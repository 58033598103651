import { useTranslation } from "react-i18next";
import MainContainer from "../../UI/MainContainer";
import PrimaryButton from "../../UI/PrimaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import i18next from "i18next";
import SearchSection from "../../UI/SearchSection";
import { useEffect, useState } from "react";
import DropdownWithCheckbox, { DropdownType } from "../../UI/DropdownWithCheckbox";
import {
  convertMsgStatusToDDItem,
  convertSmsProviderToDDItem,
  ListMessageObj,
  MessageStatus,
  ReceiverDeliveryStatus,
  RepeatFrequency,
} from "./MsgModel";
import MessagesTable, { MsgListSortConfig } from "./MessagesTable";
import IdValueType from "../../../types/IdValueType";
import { useHistory } from "react-router-dom";
import MsgManagementAPI from "../../../store/api/MsgManagementAPI";
import DatePicker from "../../UI/DatePicker";
import moment from "moment";
import NoResultMessage from "../../UI/NoResultMessage";
import FilterTag from "../../UI/FilterTag";

export type MsgListFilterConfig = {
  showFilterSection: boolean;
  statuses: Array<IdValueType>;
  provider: Array<IdValueType>;
  sendTimeFrom?: Date;
  sendTimeTo?: Date;
  formattedSendTimeFrom?: string;
  formattedSendTimeTo?: string;
};

const allItem: IdValueType = { id: "", name: "", type: [] };

export type PaginationConfig = {
  pageSize: number;
  pageIndex: number;
};

const DEFAULT_FILTER_CONFIG: MsgListFilterConfig = {
  showFilterSection: false,
  statuses: [allItem],
  provider: [allItem],
};

export default function MessageManagement() {
  const { t } = useTranslation();
  const history = useHistory();
  const { getMessageList, stopSchedulingMessage } = MsgManagementAPI();

  const [messages, setMessages] = useState<ListMessageObj[]>([]);
  const [paginationConfig, setPaginationConfig] = useState<PaginationConfig>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [searchPhrase, setSearchPhrase] = useState<string | undefined>(undefined);
  const [filterConfig, setFilterConfig] = useState<MsgListFilterConfig | undefined>(undefined);
  const [sortConfig, setSortConfig] = useState<MsgListSortConfig | undefined>(undefined);
  const [tempFilterConfig, setTempFilterConfig] = useState<MsgListFilterConfig>(DEFAULT_FILTER_CONFIG);
  const [listIsLoading, setListIsLoading] = useState(true);

  let messageStatusesArray = convertMsgStatusToDDItem([allItem]);
  let smsProviderArray = convertSmsProviderToDDItem([allItem]);

  let sendTimeFilterTagValue = "";
  if (filterConfig?.sendTimeFrom && filterConfig.sendTimeTo) {
    sendTimeFilterTagValue = `${filterConfig.formattedSendTimeFrom} - ${filterConfig.formattedSendTimeTo}`;
  } else if (filterConfig?.sendTimeFrom && !filterConfig?.sendTimeTo) {
    sendTimeFilterTagValue = `${t("from")} ${filterConfig.formattedSendTimeFrom}`;
  } else if (!filterConfig?.sendTimeFrom && filterConfig?.sendTimeTo) {
    sendTimeFilterTagValue = `${t("to")} ${filterConfig.formattedSendTimeTo}`;
  }

  useEffect(() => {
    if (filterConfig) setTempFilterConfig(filterConfig);
  }, [filterConfig]);

  useEffect(() => {
    getMessages();
  }, [paginationConfig, sortConfig, filterConfig]);

  /*
  I used seperate useEffect for search operation to set page index to 0 while searching.
  */
  useEffect(() => {
    setPaginationConfig((prevState) => ({
      ...prevState,
      pageIndex: 0,
    }));
  }, [searchPhrase]);

  const getMessages = () => {
    setListIsLoading(true);

    getMessageList(
      async (response: Response) => {
        const res = await response.json();
        setTotalDataCount(res.count);

        const messages: Array<ListMessageObj> = [];
        res.results.forEach((msg: any) => {
          messages.push({
            uniqueKey: msg.unique_key,
            recivers: msg.receiver_infos
              .filter(
                (rc: any) =>
                  ReceiverDeliveryStatus[rc.status as keyof typeof ReceiverDeliveryStatus] !== ReceiverDeliveryStatus.FI
              ) // Remove future receivers
              .map((rc: any) => rc.full_name),
            futureReceivers: msg.receiver_infos
              .filter(
                (rc: any) =>
                  ReceiverDeliveryStatus[rc.status as keyof typeof ReceiverDeliveryStatus] === ReceiverDeliveryStatus.FI
              )
              .map((rc: any) => rc.full_name),
            repeatFrequency: msg.repeat_frequency,
            repeatFrequencyType: RepeatFrequency[msg.repeat_frequency_type as keyof typeof RepeatFrequency],
            message: msg.text_message["en"],
            msgStatus: MessageStatus[msg.status as keyof typeof MessageStatus],
            note: msg.note,
            sendTime: msg.send_date,
            smsProvider: msg.sms_provider,
            receiversStatuses: msg.receiver_info_status
              .filter(
                (rc: any) =>
                  ReceiverDeliveryStatus[rc.type as keyof typeof ReceiverDeliveryStatus] !== ReceiverDeliveryStatus.FI
              ) // Remove future receivers
              .map((msg: { type: string; count: number }) => ({
                status: ReceiverDeliveryStatus[msg.type as keyof typeof ReceiverDeliveryStatus],
                count: msg.count,
              })),
          });
        });

        setMessages(messages);
        setListIsLoading(false);
      },
      paginationConfig.pageIndex,
      paginationConfig.pageSize,
      searchPhrase,
      sortConfig,
      filterConfig
    );
  };

  const hasSomeFilter = () => {
    return (
      filterConfig &&
      (!filterConfig.statuses.includes(allItem) ||
        !filterConfig.provider.includes(allItem) ||
        filterConfig.sendTimeFrom ||
        filterConfig.sendTimeTo)
    );
  };

  const generateFilterTags = () => {
    if (!hasSomeFilter()) return;

    return (
      <div className="flex px-8 items-center w-full mb-4">
        <label className="mr-2">{t("applied_filter")}</label>

        {filterConfig?.statuses && !filterConfig?.statuses.includes(allItem) && (
          <FilterTag
            className="mx-4"
            label={t("status")}
            value={filterConfig.statuses.map((stat) => stat.name).join(",")}
            onClose={() => {
              setTempFilterConfig((preValue) => ({
                ...preValue,
                statuses: [allItem],
              }));

              setFilterConfig((preValue) => ({
                ...preValue!,
                statuses: [allItem],
              }));
            }}
          />
        )}

        {(filterConfig?.sendTimeFrom || filterConfig?.sendTimeTo) && (
          <FilterTag
            label={t("send_time")}
            value={sendTimeFilterTagValue}
            onClose={() => {
              setFilterConfig((preValue) => ({
                ...preValue!,
                sendTimeFrom: undefined,
                formattedSendTimeFrom: undefined,
                sendTimeTo: undefined,
                formattedSendTimeTo: undefined,
              }));
            }}
          />
        )}

        {filterConfig?.provider && !filterConfig?.provider.includes(allItem) && (
          <FilterTag
            className="mx-4"
            label={t("provider")}
            value={filterConfig.provider.map((provider) => provider.name).join(",")}
            onClose={() => {
              setTempFilterConfig((preValue) => ({
                ...preValue,
                provider: [allItem],
              }));

              setFilterConfig((preValue) => ({
                ...preValue!,
                provider: [allItem],
              }));
            }}
          />
        )}
      </div>
    );
  };

  return (
    <MainContainer>
      <main className="alignment mb-8">
        <div className="flex justify-between items-center">
          {/* Header Section */}
          <h3 data-testid="message_management-title" className="flex text-lg text-black27 font-semibold items-center">
            {t("message_management")}
            <label className="bg-gray-41 text-white text-sm mx-4 rounded-[4px] px-2 py-[2px]">{t("beta")}</label>
          </h3>

          <PrimaryButton onClick={() => history.push(`/messaging/add`)} primaryOrNot>
            <div className="flex items-center">
              <FontAwesomeIcon
                icon={faPlus}
                className={`${i18next.language === "ar" ? "ml-[10px]" : "mr-[10px]"} mt-[2px]`}
              />
              <label className="cursor-pointer">{t("add_health_message")}</label>
            </div>
          </PrimaryButton>
        </div>

        {/* Search and Filter Section */}
        <section className="flex bg-white mt-4 flex-col items-center justify-center">
          <SearchSection
            onSearch={setSearchPhrase}
            isFilterMode={tempFilterConfig.showFilterSection}
            onSliderClicked={() =>
              setTempFilterConfig({ ...tempFilterConfig, showFilterSection: !tempFilterConfig.showFilterSection })
            }
          />
          <hr className="h-[6px] bg-gray-f5 border-none" />
          {tempFilterConfig.showFilterSection && (
            <div className="flex w-full px-8 flex-wrap">
              <div className="flex flex-1 pb-6 flex-col sm:flex-row">
                <div className="mr-6 rtl:ml-6 rtl:mr-0">
                  <label className="text-sm text-black41">{t("status")}</label>
                  <DropdownWithCheckbox
                    type={DropdownType.PRIMARY}
                    extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
                    onOptionSelected={(option) => {
                      setTempFilterConfig({ ...tempFilterConfig, statuses: option });
                    }}
                    keys={messageStatusesArray}
                    selectedKey={tempFilterConfig.statuses}
                    getValueByKey={(key) => {
                      if (key === "") return t("all");
                      return messageStatusesArray.find((msgStatus) => msgStatus.name === key)?.name ?? "";
                    }}
                  />
                </div>

                <div className="mx-1">
                  <label className="text-sm text-black41">{t("send_time")}</label>
                  <DatePicker
                    inputClassName="w-[124px] placeholder:text-gray-d7"
                    containerClassName="w-[124px]"
                    selected={tempFilterConfig.sendTimeFrom ?? null}
                    onChange={(newDate: Date | null) =>
                      setTempFilterConfig({
                        ...tempFilterConfig,
                        sendTimeFrom: newDate ?? undefined,
                        formattedSendTimeFrom: moment(newDate).locale("en").format("DD/MM/yyyy"),
                      })
                    }
                    placeholderText={t("from")}
                    maxDate={tempFilterConfig.sendTimeTo}
                  />
                </div>

                <div className="flex h-full items-end mt-2 sm:mt-0">
                  <DatePicker
                    inputClassName="w-[124px] placeholder:text-gray-d7"
                    containerClassName="w-[124px]"
                    selected={tempFilterConfig.sendTimeTo ?? null}
                    onChange={(newDate: Date | null) =>
                      setTempFilterConfig({
                        ...tempFilterConfig,
                        sendTimeTo: newDate ?? undefined,
                        formattedSendTimeTo: moment(newDate).locale("en").format("DD/MM/yyyy"),
                      })
                    }
                    placeholderText={t("to")}
                    minDate={tempFilterConfig.sendTimeFrom}
                  />
                </div>

                <div className="ml-6 rtl:mr-6 rtl:ml-0">
                  <label className="text-sm text-black41">{t("provider")}</label>
                  <DropdownWithCheckbox
                    type={DropdownType.PRIMARY}
                    extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
                    onOptionSelected={(option) => {
                      setTempFilterConfig({ ...tempFilterConfig, provider: option });
                    }}
                    keys={smsProviderArray}
                    selectedKey={tempFilterConfig.provider}
                    getValueByKey={(key) => {
                      if (key === "") return t("all");
                      return smsProviderArray.find((provider) => provider.name === key)?.name ?? "";
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-1 items-center justify-end">
                {hasSomeFilter() && (
                  <label
                    className="link mx-6"
                    onClick={() => {
                      setTempFilterConfig({ ...DEFAULT_FILTER_CONFIG, showFilterSection: true });
                      setFilterConfig(undefined);
                    }}
                  >
                    {t("reset")}
                  </label>
                )}
                <PrimaryButton onClick={() => setFilterConfig(tempFilterConfig)} primaryOrNot title={t("apply")} />
              </div>
            </div>
          )}

          {generateFilterTags()}
        </section>

        <section className="flex bg-white h-full mt-2 px-6 py-4 items-center justify-center">
          {messages.length === 0 && !listIsLoading ? (
            <NoResultMessage noResultOrNoData={true} />
          ) : (
            <MessagesTable
              data={messages}
              pageCount={Math.ceil(totalDataCount / paginationConfig.pageSize)}
              listIsLoading={listIsLoading}
              pagiantionConfig={paginationConfig}
              setPaginationConfig={setPaginationConfig}
              sortChanged={(sortConfig?: MsgListSortConfig) => setSortConfig(sortConfig)}
              totalDataCount={totalDataCount}
              onStopClick={(messageUniqueKey: string) => {
                stopSchedulingMessage(messageUniqueKey, async (response: Response) => {
                  if (response.ok) {
                    getMessages();
                  }
                });
              }}
              onPreviewClick={(messageUniqueKey: string) =>
                history.push(`/messaging/view`, { messageId: messageUniqueKey })
              }
            />
          )}
        </section>
      </main>
    </MainContainer>
  );
}
