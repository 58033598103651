import { t } from "i18next";
import Modal from "../../UI/Modal";
import PrimaryButton from "../../UI/PrimaryButton";
import { useTranslation } from "react-i18next";
import MessageSenderSelector from "./MessageSenderSelector";
import { SMSProvider } from "./MsgModel";
import { useState } from "react";

interface Props {
  onClose: () => void;
  onResendClicked: (provider: SMSProvider) => void;
}

export default function ResendModal(props: Props) {
  const { t } = useTranslation();
  const [provider, setProvider] = useState<SMSProvider>(SMSProvider.TO);

  return (
    <Modal>
      <div className="flex flex-col py-4 px-6 text-gray-41">
        <h1 className="font-semibold">{t("resend")}</h1>
        <p className="mt-10 mb-4 text-sm">{t("resend_message_desc")}</p>

        <MessageSenderSelector
          onProviderSelected={(smsProvider) => setProvider(smsProvider)}
          currentSelectedProvider={provider}
        />

        <div className="flex items-center justify-end mt-10">
          <label className="link text-gray-41 mr-11" onClick={props.onClose}>
            {t("cancel")}
          </label>
          <PrimaryButton
            onClick={() => {
              props.onResendClicked(provider);
              props.onClose();
            }}
            primaryOrNot
            title={t("yes_resend")}
          />
        </div>
      </div>
    </Modal>
  );
}
